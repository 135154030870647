import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

class Fotix extends React.Component {
  state = {
    listOfImages: []
  };

  importAll(r) {
    return r.keys().map(r);
  }

  componentDidMount() {
    const images = this.importAll(require.context('../portraits/', false, /\.(png|jpe?g|svg)$/));
    // Ensure aspect ratio consistency
    const aspectRatioAdjustedImages = images.map(image => {
      const img = new Image();
      img.src = image;
      return new Promise((resolve, reject) => {
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL('image/jpeg'));
        };
        img.onerror = reject;
      });
    });

    Promise.all(aspectRatioAdjustedImages)
      .then(listOfImages => {
        this.setState({ listOfImages });
      })
      .catch(error => {
        console.error('Error loading images:', error);
      });
  }

  render() {
    return (
      <Container className="contenedor">
        <div className="block">
          {this.state.listOfImages.map((image, index) => (
            <div key={index} style={{ display: 'inline-block', marginBottom: '10px', width: '100%', maxWidth: '400px' }}>
              <Image className="feed" src={image} alt="" rounded style={{ width: '100%', height: 'auto' }} />
            </div>
          ))}
        </div>
      </Container>
    )
  }
};

export default Fotix;
