import React,{render} from 'react';
import { Component } from 'react';
import Fotix4 from './component4';
import Fotix5 from './component5';
import Fotix6 from './component6';
import Fotix7 from './component7';
import Fotix8 from './component8';


class Foto120 extends Component {
    render () {
        return (
          <>
            <Fotix4 />
            <Fotix5 />
            <Fotix6 />
            <Fotix7 />
            <Fotix8 />
          </>
        )
      }
}

export default Foto120;